import queryString from "query-string";

type QueryValue = string | number;

type QueryType = "hash" | "search";

export default function useQueryString(queryType: QueryType): {
  query: queryString.ParsedQuery<string>;
  setQuery: (key: string, value: QueryValue) => void;
  removeQuery: (key: string) => void;
} {
  const query = queryString.parse(window.location[queryType]);

  const setQuery = (key: string, value: QueryValue) =>
    (window.location[queryType] = queryString.stringify({ ...query, [key]: value }));

  const removeQuery = (key: string) => {
    const newQuery = query;

    delete newQuery[key];

    window.location[queryType] = queryString.stringify(newQuery);
  };

  return { query, setQuery, removeQuery };
}
