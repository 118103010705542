import { Row } from "react-table";
import { CustomHeader } from "types/task";

import { toArray } from "./input";

export function getOrderColumns(headers: string[], columns: any) {
  if (!headers?.length) return columns;

  const filteredColumns = columns?.filter((item: any) => {
    return typeof item?.accessor === "string" && headers?.includes(item?.accessor ?? "");
  });

  const sortedColumns = headers?.reduce((acc: any, cur, index) => {
    if (filteredColumns?.find((val: any) => (val?.accessor ?? "") === cur)) {
      acc[index] = filteredColumns?.find((val: any) => (val?.accessor ?? "") === cur);
    }

    return acc;
  }, []);

  return sortedColumns;
}

export function getCurrentHeaderOptions<T>(
  header: CustomHeader | undefined,
  accessor: keyof T,
): any[] {
  if (!header) return [];

  const selected = header[accessor as string];

  // unique array
  return [...new Set(selected)];
}

export const getArrayDisplayValue = (
  options: any[],
  stringArray: string,
  display: "label" | "value",
) =>
  toArray(stringArray)?.map((code) => {
    const currentCode = options?.find((val) => val?.value === code);

    return (display === "label" ? currentCode?.label : currentCode?.value) || code;
  });

export const getDisplayValue = (options: any[], value: string, display: "label" | "value") => {
  const currentType = options?.find((val) => val?.value === value);

  return (display === "label" ? currentType?.label : currentType?.value) ?? value;
};

export const getObjectDisplayValue = (option: any, key: string) => {
  return option[key];
};

export const mappedSelectedRows = (selectedIds: number[], listData: any[]) =>
  selectedIds?.reduce((acc: Record<string, boolean>, cur: number) => {
    const currentDataIndex = listData?.findIndex((val) => (val?.id as number) === cur);

    acc[currentDataIndex] = true;

    return acc;
  }, {});

export const getSelectedRowData = (listData: any[], selectedIds: number[]) => {
  const selectedData = listData?.filter((item) => selectedIds?.includes(item?.id));

  return selectedData || [];
};

// export const getSelectRowIndexes = (selectedRows: Record<string, boolean>) => {
//   return selectedRows && Object.keys(selectedRows)?.length
//     ? Object.keys(selectedRows)?.map((item) => parseInt(item, 10))
//     : [];
// };

export const mapToSelectedRows = (selectedIds: number[]) => {
  return selectedIds?.reduce((acc: Record<number, boolean>, cur: number) => {
    acc[cur] = true;

    return acc;
  }, {});
};

export const mapToSelectedIds = (selectedRows: Record<number, boolean>) => {
  return selectedRows && Object.keys(selectedRows)?.length
    ? Object.keys(selectedRows)?.map((item) => parseInt(item, 10))
    : [];
};

export const getMappedFilterOptions = (
  filters: Record<string, any[]>,
): { name: string; value: any[] }[] => {
  const enteries = Object.entries(filters);

  return enteries?.map((item) => {
    return {
      name: item[0],
      value: item[1],
    };
  });
};

export function multiSelectFilter(rows: Row<any>[], columnIds: any, filterValue: any) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        if (typeof row.original[columnIds] === "string" && row.original[columnIds]?.includes(",")) {
          const values = row.original[columnIds]?.split(",");
          return values.some((value: any) => filterValue.includes(value));
        }

        return filterValue.includes(String(row.original[columnIds]));
      });
}
