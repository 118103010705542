export const downloadFile = (fileData: any, fileName: string) => {
  // other browsers
  const blob: Blob = new File([fileData], fileName, { type: "application/json" });
  const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
  const blobUrl: string = URL.createObjectURL(blob);

  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
};

export const downloadExcelFile = (fileData: any, fileName: string) => {
  // other browsers
  const blob: Blob = new File([fileData], fileName, {
    type: "application/vnd.ms-excel;charset=gb2312",
  });
  const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
  const blobUrl: string = URL.createObjectURL(blob);

  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
};

export const getFileUrl = (fileData: any, fileName = "Untitled") => {
  let blob = null;

  // other browsers
  // blob = new Blob([data], { type: 'application/octet-stream' });
  blob = new File([fileData], fileName, { type: "application/json" });

  const url = URL.createObjectURL(blob);
  return url;
};
