import { createAction } from "@reduxjs/toolkit";
import { SkuGroupingState } from "state/skuGrouping/reducer";

export const setCombinedTaskByWicNumber = createAction<
  SkuGroupingState["combinedTasksByWicNumber"]
>("skuGrouping/setCombinedTaskByWicNumber");

export const setOriginalListData = createAction<SkuGroupingState["originalListData"]>(
  "skuGrouping/setOriginalListData",
);

export const setSelectedGroupData = createAction<SkuGroupingState["selectedGroups"]>(
  "skuGrouping/setSelectedGroupData",
);
