import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";

import API from "constants/api";
import { BUYER } from "constants/global";
import { useEffect, useMemo, useState } from "react";
import { Country, FilterOptions } from "types/global";
import { SkuGroupingPayload } from "types/sku-group";
import { CustomHeader } from "types/task";
import { useErrorAlert, useSuccessAlert } from "./useAlert";
import { useUserRole } from "./useUserRole";

function useFetchSkuGrouping<T>({
  method = "GET",
  api = API.SKU_GROUPING,
  id = "",
  url = "",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
}) {
  const { setError } = useErrorAlert();

  const { isBuyer } = useUserRole();

  const [data, setData] = useState<T>();

  const PATH = isBuyer ? `/${BUYER}${api}` : api;

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method: method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: data as T,
      isLoading,
      refetch,
      error,
    }),
    [id, url, data, isLoading],
  );
}

function useUpdateSkuGrouping<Req, Res = { id?: string }>({
  method = "POST",
  api = API.SKU_GROUPING,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const { isBuyer } = useUserRole();

  const PATH = isBuyer ? `/${BUYER}${api}` : api;

  const [data, setData] = useState<Res>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method: method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (payload: Req) => refetch({ data: payload }),
      error,
    }),
    [api, id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

export function useCreateSkuGrouping<T = SkuGroupingPayload>() {
  return useUpdateSkuGrouping<T>({
    successAlertEnabled: true,
    successMessage:
      "SKU grouping created successfully and the test request has been sent to Vendor.",
  });
}

export function useEditSkuGrouping<T = SkuGroupingPayload>(taskId: string) {
  return useUpdateSkuGrouping<T>({
    method: "PUT",
    id: taskId,
    successAlertEnabled: true,
    successMessage:
      "SKU grouping updated successfully and the revised test request has been sent to Vendor",
  });
}

export function useCompletedSkuList<Req = FilterOptions>() {
  return useUpdateSkuGrouping<Req>({
    url: "skus",
  });
}

export function useCompletedSkuListFilters<Req = FilterOptions, Res = CustomHeader>() {
  return useUpdateSkuGrouping<Req, Res>({
    url: "skus/filter_options",
  });
}

export function useGroupingBrandOptions<Req = { countries: Country[] }>() {
  return useUpdateSkuGrouping<Req>({
    url: "brands",
  });
}

export function useGroupingSubcategoryOptions<Req = { countries: Country[]; brand: string }>() {
  return useUpdateSkuGrouping<Req>({
    url: "sub_categories",
  });
}
