enum ROUTES {
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/resetPassword",

  SKU_NEW_LIST = "/sku/new-list",
  SKU_ALL_LIST = "/sku/all-list",
  SKU_PROFILE = "/sku/profile",
  SKU_CREATE = "/sku/create",
  SKU_CAP_APPROVAL = "/sku/cap/approval",
  SKU_CAP_SUBMIT = "/sku/cap/submit",
  SKU_CAP_RESUBMIT = "/sku/cap/resubmit",
  SKU_CAP = "/sku/cap",
  SKU_TEST_REPORT_APPROVAL = "/sku/test-report-approval",

  OPEN_TASK = "/open_task",

  SKU_GROUPING_EDIT = "/sku-grouping/edit",

  TEST_MATRIX = "/test-matrix",
  TEST_MATRIX_CREATE = "/test-matrix/create",
  TEST_MATRIX_EDIT = "/test-matrix/edit",

  TEST_ITEM = "/test-item",
  TEST_ITEM_CREATE = "/test-item/create",
  TEST_ITEM_EDIT = "/test-item/edit",

  BRAND_CREATE = "/brand/create",
  SUB_CATEGORY_CREATE = "/subcategory/create",
  SUB_CATEGORY_EDIT = "/subcategory/edit",

  CHANGE_REQUEST = "/change-request",

  UPLOAD_AUDIT = "/upload-audit",
  SUPPLIER_LIST = "/supplier-list",
  SUPPLIER = "/supplier",
  SUPPLIER_DETAIL = "/supplier/details",
  FACTORY_LIST = "/factory-list",
  FACTORY = "/factory",
  ANNOUNCEMENT = "/announcement",
  ANALYTICS = "/analytics",

  SUBMISSIONS = "/submissions",
  SUBMISSIONS_PROFILE = "/submissions/profile",
  EDIT_BILLING = "/billing/edit",
  ADD_LAB_FLOW = "/lab/add",

  UPDATE_STATUS = "/update_status",
  UPDATE_TRF = "/update_trf",

  REUPLOAD_REPORT = "/reupload_report",
  REVISE_REPORT = "/revise_report",

  ADMIN_USER_LIST = "/admin/user-list",
}

export default ROUTES;
