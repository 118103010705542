import { useState } from "react";
import { getSelectedRowData, mapToSelectedIds, mapToSelectedRows } from "utils/table";

export function useSelectTableRow<T>(data: T[]) {
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const selectedRowIds = mapToSelectedIds(selectedRows);

  const setDefaultSelectedRows = (selectedIds: number[]) => {
    setSelectedRows(mapToSelectedRows(selectedIds));
  };

  return {
    selectedRowIds,
    selectedRowData: getSelectedRowData(data, selectedRowIds),
    selectedRows,
    setSelectedRows,
    setDefaultSelectedRows,
  };
}
