import { TestItemStatus, TestLevel } from "types/test-item";

export const DEFAULT_TEST_MATRIX_LIST_STATUS_FILTER = {
  name: "status",
  value: [TestItemStatus.Active],
};

export const ARCHIVED_TEST_MATRIX_LIST_STATUS_FILTER = {
  name: "status",
  value: [TestItemStatus.Archived],
};

export const TEST_ITEM_STATUS = {
  DRAFT: "Draft",
  ACTIVE: "Active",
  PENDING_APPROVED: "Pendning Approved",
  ARCHIVED: "Archived",
};

export const TESTING_LEVEL: { [key: string]: string } = {
  A: "Analytical",
  F: "Flammability",
  L: "Labeling and Product Information",
  P: "Performance and Construction",
  Q: "Performance and Construction - Quality",
  C: "Colorfastness - Quality",
};

export const TESTING_LEVEL_OPTIONS = [
  {
    label: TESTING_LEVEL.A,
    value: TestLevel.A,
  },
  {
    label: TESTING_LEVEL.F,
    value: TestLevel.F,
  },
  {
    label: TESTING_LEVEL.L,
    value: TestLevel.L,
  },
  {
    label: TESTING_LEVEL.P,
    value: TestLevel.P,
  },
  {
    label: TESTING_LEVEL.Q,
    value: TestLevel.Q,
  },
  {
    label: TESTING_LEVEL.C,
    value: TestLevel.C,
  },
];

export const TEST_LEVEL_NORMAL_LIST = [TestLevel.A, TestLevel.F, TestLevel.L, TestLevel.P];

export const TEST_LEVEL_QUALITY_LIST = [TestLevel.Q, TestLevel.C];

export const TEST_LEVEL_LIST = [...TEST_LEVEL_NORMAL_LIST, ...TEST_LEVEL_QUALITY_LIST];

export const DEFAULT_TEST_LEVEL_NORMAL_MAPPING = {
  [TestLevel.A]: [],
  [TestLevel.F]: [],
  [TestLevel.L]: [],
  [TestLevel.P]: [],
};

export const DEFAULT_TEST_LEVEL_QUALITY_MAPPING = {
  [TestLevel.Q]: [],
  [TestLevel.C]: [],
};
