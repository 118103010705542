import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import { TextField, OutlinedInputProps } from "@mui/material";

import { textOrUnknown } from "utils";
import { TextProps, EditInput } from "components/core";

export interface EditTextFieldProps {
  loading?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: OutlinedInputProps["placeholder"];
  sx?: OutlinedInputProps["sx"];
  textProps?: Omit<TextProps, "children">;
  name?: OutlinedInputProps["name"];
  value: string;
  onSubmit: (selected: string) => void;
}

export default function EditTextField({
  readOnly = false,
  disabled = false,
  loading = false,
  value = "",
  textProps,
  onSubmit,
  ...rest
}: EditTextFieldProps) {
  const [inputValue, setInputValue] = useState(value);

  const handleClose = () => {
    setInputValue(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: eventVal } = event.target;

    setInputValue(eventVal);
  };

  const handleSubmit = async () => {
    await onSubmit(inputValue);

    handleClose();
  };

  useEffect(() => {
    setInputValue(value);
  }, [JSON.stringify(value)]);

  return (
    <EditInput
      disabled={disabled}
      readOnly={readOnly}
      textProps={textProps}
      value={textOrUnknown(inputValue) as string}
      onSubmit={handleSubmit}
      onClose={handleClose}
      inputComponent={
        <TextField
          {...rest}
          autoFocus
          value={textOrUnknown(inputValue)}
          variant="outlined"
          color="primary"
          disabled={loading}
          onChange={handleChange}
        />
      }
    />
  );
}
