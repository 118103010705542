import useAxios from "axios-hooks";
import API from "constants/api";
import { ADMIN, STORAGE_ROLE_KEY, STORAGE_TOKEN_KEY } from "constants/global";
import { useEffect, useMemo } from "react";
import { User } from "types/user";
import { useErrorAlert } from "./useAlert";

export function useAdminUserList<TData = User[]>(userListType: "buyers" | "suppliers" | "labs") {
  const { setError } = useErrorAlert();

  const baseUrl = `${API.ADMIN}/list/${userListType}`;

  const [{ data: responseData, loading: isLoading, error }, refetch] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      data: responseData?.data as TData,
      isLoading,
      refetch: ({ currentPage, rowsPerPage }: { currentPage: number; rowsPerPage: number }) => {
        return refetch({
          url: baseUrl,
          params: {
            currentPage,
            rowsPerPage,
          },
        });
      },
    }),
    [isLoading, userListType],
  );
}

export function useHackAccount() {
  const { setError, setAppError } = useErrorAlert();

  const [{ data: responseData, loading: isLoading, error }, refetch] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      data: responseData?.data,
      isLoading,
      refetch: (userId: number) =>
        refetch({
          url: `/${ADMIN}/user_account/${userId}`,
        }).then(async (res) => {
          if (!res?.data?.data) {
            setAppError({
              code: 0,
              message: "error",
            });

            return;
          }

          const { role, token } = res.data.data;

          const bearerToken = `Bearer ${token}`;

          localStorage.setItem(STORAGE_ROLE_KEY, role);
          localStorage.setItem(STORAGE_TOKEN_KEY, bearerToken);

          const hackLink = `${window.location.origin}/hack-account/${token}`;

          window.open(hackLink);
        }),
      error,
    }),
    [isLoading],
  );
}
