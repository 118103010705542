import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import React from "react";

export interface RadioButtonProps extends RadioGroupProps {
  label: string;
  options: {
    label: string;
    value: string;
  }[];
  error?: boolean;
  helperText?: string | false;
  color?: RadioProps["color"];
  disabled?: RadioProps["disabled"];
  sx?: FormControlProps["sx"];
}

export default function RadioButton({
  label,
  options,
  name = "controlled-radio-buttons-group",
  value,
  color = "primary",
  error,
  helperText,
  disabled = false,
  sx,
  onChange,
  ...props
}: RadioButtonProps) {
  return (
    <FormControl error={error} sx={sx}>
      <FormLabel id="radio-buttons-group">{label}</FormLabel>

      <RadioGroup
        aria-labelledby="radio-buttons-group"
        name={name}
        value={value}
        onChange={onChange}
        {...props}
      >
        {options?.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={
              <Radio
                sx={{
                  "> span": {
                    color: error ? "#D42600" : "inherit",
                  },
                  // "+ span.MuiTypography-root": {
                  //   color: error ? "#D42600" : "inherit"
                  // }
                }}
                disabled={disabled}
                color={color}
              />
            }
            label={option.label}
          />
        ))}
      </RadioGroup>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
