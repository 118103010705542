import React, { useState, useEffect } from "react";

import {
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Checkbox,
  Button,
  Box,
  TextField,
} from "@mui/material";

import Text from "../Text";
import camelCaseTextToWord from "../../../utils/camelCaseTextToWord";

export interface TransferListProps {
  columnView?: string;
  leftText?: string;
  rightText?: string;
  defaultLeft?: string[];
  defaultRight?: string[];
  allCheckDisabled?: boolean;
  orderCheckDisabled?: boolean;
  checkbox?: boolean;
  loading?: boolean;
  onUpdate: ({ left, right }: { left: string[]; right: string[] }) => void;
  onInputChange: (value: string) => void;
}

function not(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function reorder(checked: string[], fromIndex: number, order: "up" | "down") {
  const reorderItem = checked?.filter((_, index: number) => index === fromIndex)[0];

  const toIndex = order === "up" ? fromIndex - 1 : fromIndex + 1;

  const updatedChecked = checked.filter((_, index) => index !== fromIndex);

  updatedChecked.splice(toIndex, 0, reorderItem);

  return updatedChecked;
}

export default function TransferList({
  columnView = "",
  leftText,
  rightText,
  defaultLeft = [],
  defaultRight = [],
  allCheckDisabled = false,
  orderCheckDisabled = false,
  checkbox = false,
  loading = false,
  onUpdate,
  onInputChange,
}: TransferListProps) {
  const [checked, setChecked] = useState<readonly string[]>([]);
  const [left, setLeft] = useState<string[]>([]);
  const [right, setRight] = useState<string[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const getCheckedIndexes = (property: "left" | "right") => {
    const selected = property === "left" ? left : right;
    const selectedChecked = property === "left" ? rightChecked : rightChecked;

    return selected?.reduce((acc: number[], cur: string, index: number) => {
      if (selectedChecked.includes(cur)) {
        acc.push(index);
      }

      return acc;
    }, []);
  };

  // only support reorder 1 item
  const canRightCheckedOrder = right?.length && rightChecked?.length === 1;
  const canRightCheckedOrderUp = !!getCheckedIndexes("right")[0];
  const canRightCheckedOrderDown = rightChecked.length !== getCheckedIndexes("right")[0];

  const handleSetDefaultList = () => {
    setLeft(defaultLeft);
    setRight(defaultRight);

    onUpdate({ left: defaultLeft, right: defaultRight });
  };

  const handleClearCheck = () => {
    setChecked([]);
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleOrderUp = () => {
    const currentIndexes = getCheckedIndexes("right")[0];

    const updatedList = reorder(right, currentIndexes, "up");

    setRight(updatedList);

    onUpdate({ left, right: updatedList });
  };

  const handleOrderDown = () => {
    const currentIndexes = getCheckedIndexes("right")[0];

    const updatedList = reorder(right, currentIndexes, "down");

    setRight(updatedList);

    onUpdate({ left, right: updatedList });
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);

    onUpdate({ left: right.concat(left), right: [] });
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    onUpdate({ left: not(left, leftChecked), right: right.concat(leftChecked) });
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    onUpdate({ left: left.concat(rightChecked), right: not(right, rightChecked) });
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);

    onUpdate({ left: left.concat(right), right: [] });
  };

  const customList = (
    items: readonly string[],
    nameActionEnabled?: boolean,
    actionEnabled?: boolean,
  ) => (
    <>
      <Paper
        elevation={2}
        sx={{
          width: "100%",
          height: "280px",
          overflow: "auto",
          marginTop: "8px",
        }}
      >
        <List dense component="div" role="list">
          {items.map((value: string, index: number) => {
            const labelId = `transfer-list-item-${index}-label`;

            return checkbox ? (
              <ListItemButton key={index} role="listitem" onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                    disabled={loading}
                  />
                </ListItemIcon>

                <ListItemText id={labelId} primary={value} />
              </ListItemButton>
            ) : (
              <ListItemButton
                key={index}
                role="listitem"
                onClick={handleToggle(value)}
                selected={checked.includes(value)}
                disabled={loading}
              >
                <ListItemText id={labelId} primary={camelCaseTextToWord(value)} />
              </ListItemButton>
            );
          })}
          <ListItem />
        </List>
      </Paper>

      {nameActionEnabled && (
        <Grid
          sx={{ display: "flex", alignItems: "center", marginTop: "4px" }}
          container
          spacing={0.5}
        >
          <Grid item xs={12}>
            Column View Name:&nbsp;
          </Grid>

          <Grid item xs={12}>
            <TextField
              sx={{ input: { padding: "8px" } }}
              fullWidth
              name="columnView"
              value={columnView}
              onChange={(event) => {
                onInputChange(event.target.value);
              }}
              disabled={loading}
            />
          </Grid>
        </Grid>
      )}

      {actionEnabled && (
        <Grid
          sx={{ display: "flex", alignItems: "center", marginTop: "4px" }}
          container
          spacing={0.5}
        >
          <Grid item>Actions:&nbsp;</Grid>

          <Grid item>
            <Button
              sx={{ fontSize: "12px", textTransform: "initial" }}
              variant="text"
              onClick={handleSetDefaultList}
              disabled={loading}
            >
              Default view
            </Button>
          </Grid>

          <Grid item>
            <Button
              sx={{ fontSize: "12px", textTransform: "initial" }}
              variant="text"
              onClick={handleClearCheck}
              disabled={!checked?.length || loading}
            >
              Clear selected
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );

  useEffect(() => {
    if (defaultLeft && defaultLeft.length > 0) {
      setLeft(defaultLeft);
    }
  }, [defaultLeft]);

  useEffect(() => {
    if (defaultRight && defaultRight.length > 0) {
      setRight(defaultRight);
    }
  }, [defaultRight]);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
      <Grid item xs={5}>
        <Text bold color="black" size="text">
          {leftText}
        </Text>

        {customList(left, true)}
      </Grid>

      <Grid
        sx={{
          alignSelf: "center",
        }}
        item
        xs={2}
      >
        <Grid container direction="column" alignItems="center">
          {!allCheckDisabled && (
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0 || loading}
              aria-label="move all right"
            >
              ≫
            </Button>
          )}

          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || loading}
            aria-label="move selected right"
          >
            &gt;
          </Button>

          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || loading}
            aria-label="move selected left"
          >
            &lt;
          </Button>

          {!allCheckDisabled && (
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0 || loading}
              aria-label="move all left"
            >
              ≪
            </Button>
          )}

          {!orderCheckDisabled && (
            <Box
              sx={{
                margin: "8px 0",
                display: "flex",
                flexFlow: "column",
              }}
            >
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleOrderUp}
                disabled={!canRightCheckedOrder || !canRightCheckedOrderUp || loading}
                aria-label="move up"
              >
                ↑
              </Button>

              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleOrderDown}
                disabled={!canRightCheckedOrder || !canRightCheckedOrderDown || loading}
                aria-label="move down"
              >
                ↓
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid item xs={5}>
        <Text bold color="black" size="text">
          {rightText}
        </Text>

        {customList(right, false, true)}
      </Grid>
    </Grid>
  );
}
