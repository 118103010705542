import { GlobalStyles, GlobalStylesProps } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { lazy, useEffect } from "react";

import axiosHooksInitializer from "api";
import globalStyles from "globalStyles";
import themeStyles from "themeStyles";

import { ErrorAppAlert, FullPageLoading, SuccessAppAlert } from "components/core";

import { useAuth, useErrorAlert, useSuccessAlert, useUserInfo, useUserRole } from "hooks";

const Routes = lazy(() => import("Routes"));
const Login = lazy(() => import("pages/Login"));

export default function App() {
  axiosHooksInitializer();

  const { error, resetError } = useErrorAlert();
  const { success, resetSuccess } = useSuccessAlert();
  const { isAuthenticated } = useAuth();

  const { data: userInfo, isLoading: isUserInfoLoading, refetch: fetchUserInfo } = useUserInfo();

  const { isBuyer } = useUserRole();

  const hasAppError = Boolean(error?.code || error?.message);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    const buyerDeptNum = userInfo?.buyerInfo?.deptNum || "";

    window.localStorage.setItem("buyerDeptNum", buyerDeptNum);
  }, [userInfo?.buyerInfo, isBuyer]);

  return (
    <ThemeProvider theme={themeStyles}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles as GlobalStylesProps["styles"]} />

      <ErrorAppAlert error={error} open={hasAppError} onClose={resetError} />

      <SuccessAppAlert
        message={success?.message}
        open={!!success?.message}
        onClose={resetSuccess}
      />

      <React.Suspense fallback={<FullPageLoading />}>
        <Routes />
      </React.Suspense>
    </ThemeProvider>
  );
}
