export default function camelCaseTextToWord(str: string | null = ""): string {
  if (!str) return "";

  if (typeof str !== "string") return str;

  const tempStr = str;
  if (str === "wicNumber") {
    return "PIM SKU SrcNum";
  }

  const result = tempStr.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

  return finalResult;
}
