import React from "react";

import {
  Select as MuiSelect,
  MenuItem,
  OutlinedInput,
  TextFieldProps,
  FormHelperText,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { BasicSelectOption } from "components/core";
import { styled } from "@mui/styles";

const StyledSelect = styled(MuiSelect)({
  "& .MuiSelect-select": {
    padding: "10px",
  },
});

export interface CheckmarkSelectProps {
  sx?: TextFieldProps["sx"];
  className?: string;
  fullWidth?: TextFieldProps["fullWidth"];
  error?: TextFieldProps["error"];
  helperText?: TextFieldProps["helperText"];
  disabled?: boolean;
  placeholder?: string;
  label?: TextFieldProps["label"];
  name: string;
  value?: string[];
  options: BasicSelectOption[];
  onChange: (selectedValues: string[]) => void;
}

export default function CheckmarkSelect({
  label,
  className = "",
  fullWidth = false,
  error = false,
  helperText,
  placeholder,
  name,
  value = [],
  disabled = false,
  options = [],
  onChange,
  ...rest
}: CheckmarkSelectProps) {
  const handleChange = (event: any) => {
    const { value: currentValues } = event.target;

    const hasAllInValue = value.includes("all");
    const hasAllInCurrent = currentValues.includes("all");

    if (hasAllInValue && !hasAllInCurrent) {
      // uncheck all values
      onChange([]);
    } else if (!hasAllInValue && hasAllInCurrent) {
      // check all values
      onChange(options?.map((item) => item.value.toString()));
    } else {
      // check 1 value except all
      const preventDuplicate = currentValues.filter((val: string, index: number, arr: string[]) => {
        return arr.findIndex((curVal) => curVal === val) === index;
      });

      onChange(
        typeof preventDuplicate === "string" ? preventDuplicate.split(",") : preventDuplicate,
      );
    }
  };

  // minor option all
  const hasOptionAll = options?.filter((item) => item?.value === "all").length;

  const isAllSelected =
    options?.length > 0 && value?.length === options.length - (hasOptionAll ? 1 : 0);

  return (
    <>
      <StyledSelect
        {...rest}
        label={label}
        displayEmpty
        multiple
        className={className}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput fullWidth={fullWidth} error={error} name={name} />}
        renderValue={(renderValue: any) => {
          if (value?.length === 0) {
            return placeholder;
          }

          return renderValue
            .map(
              (val: any) =>
                options?.filter((item) => item?.value?.toString() === val?.toString())[0]?.label,
            )
            .join(" / ");
        }}
      >
        {options?.map((option, index) => {
          return option?.value === "all" ? (
            <MenuItem key={`${option?.value}-${index}`} value={options[0]?.value}>
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected || !!value?.length}
                  indeterminate={
                    !isAllSelected && !!value?.length && value?.length < options?.length
                  }
                />
              </ListItemIcon>

              <ListItemText primary={options[0]?.label || "Select All"} />
            </MenuItem>
          ) : (
            <MenuItem key={`${option?.value}-${index}`} value={option?.value?.toString()}>
              <Checkbox checked={isAllSelected || value?.indexOf(option?.value?.toString()) > -1} />
              <ListItemText primary={option?.label} />
            </MenuItem>
          );
        })}
      </StyledSelect>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
}
