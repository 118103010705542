import React, { useState, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import { Multiselect } from "multiselect-react-dropdown";
import classnames from "classnames";
import Box from "@mui/material/Box";
import { BasicButton } from "components/core";
import { Theme } from "@mui/material";

interface SearchFilterProps {
  className?: string;
  placeholder?: string;
  submitText?: string;
  cancelText?: string;
  list: any[];
  selectedValues?: any[];
  onApplyFilter: (item: any) => void;
  onCancelFilter?: () => void;
  cbSetFilter?: (item: any) => void;
}

function SearchFilter({
  className,
  placeholder = "Search",
  submitText = "Apply",
  cancelText = "Cancel",
  list = [],
  onApplyFilter,
  onCancelFilter,
  cbSetFilter,
  selectedValues = [],
}: SearchFilterProps) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState(selectedValues);

  useEffect(() => {
    if (selectedValues) setSelectedOption(selectedValues);
  }, [selectedValues]);

  const handleOnSelect = (selectedList: any, selectedItem: any) => {
    if (selectedItem.value === "All" || selectedList.length === list.length - 1) {
      if (cbSetFilter) cbSetFilter(list.filter((item) => item.value !== "All"));
      setSelectedOption(list);
    } else {
      if (cbSetFilter) cbSetFilter(selectedList);
      setSelectedOption(selectedList);
    }
  };

  const handleOnRemove = (selectedList: any, removedItem: any) => {
    if (removedItem.value === "All") {
      if (cbSetFilter) cbSetFilter([]);
      setSelectedOption([]);
    } else if (selectedList.length === list.length - 1) {
      setSelectedOption(
        list.filter((item) => item.value !== "All" && item.value !== removedItem.value),
      );
      if (cbSetFilter)
        cbSetFilter(
          list.filter((item) => item.value !== "All" && item.value !== removedItem.value),
        );
    } else {
      if (cbSetFilter) cbSetFilter(selectedList);
      setSelectedOption(selectedList);
    }
  };

  return (
    <Box className={classnames(classes.root, className)}>
      <Multiselect
        options={list}
        displayValue="key"
        showCheckbox
        onSelect={handleOnSelect}
        onRemove={handleOnRemove}
        selectedValues={selectedOption}
        placeholder={placeholder}
      />
      <Box
        display="flex"
        justifyContent="right"
        marginRight="10px"
        marginTop="10px"
        marginBottom="10px"
      >
        <BasicButton
          onClick={() => onApplyFilter(selectedOption)}
          variant="contained"
          className={classes.applyBtn}
        >
          {submitText}
        </BasicButton>
        <BasicButton onClick={onCancelFilter} variant="outlined">
          {cancelText}
        </BasicButton>
      </Box>
    </Box>
  );
}

export default memo(SearchFilter);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    minWidth: "300px",
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "0 2px 10px rgba(35, 43, 58, 0.25)",
    borderRadius: 0,

    "> div": {
      overflowY: "hidden",
    },

    "& .search-wrapper": {
      borderRadius: 0,
      border: 0,
      borderBottom: "1px solid #cccccc",
      padding: "9px 10px",
    },

    "& .search-wrapper > span": {
      display: "none",
    },

    "& .multiselect-container": {
      height: "300px",

      "& input": {
        marginTop: 0,
      },
    },

    "& .optionListContainer": {
      display: "block",
      // height: "210px",
      marginTop: 0,
    },

    "& .optionContainer": {
      maxHeight: "initial",
      borderRadius: 0,
      background: "white",
      height: "210px",
      overflowY: "auto",
      paddingTop: 10,
      paddingLeft: 2.5,
    },

    "& .optionContainer li": {
      padding: "7px 10px",
      background: "transparent",
      color: "rgba(0, 0, 0, 0.87)",
      width: "max-content",
      display: "flex",
      alignItems: "center",
    },
    "& .optionContainer li input": {
      width: 16,
      height: 16,
    },
  },
  applyBtn: {
    marginRight: 10,
  },
}));
