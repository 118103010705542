export const isTrue = (str: string) => str === "true";

// block user to swipe forewards & backwards in browser
export const forceUserToSwipePage = (active: boolean) =>
  (document.body.style.overscrollBehaviorX = active ? "none" : "initial");

export { default as capitalize } from "./capitalize";
export * from "./date";
export * from "./file";
export * from "./filterList";
export * from "./input";
export { default as reportWebVitals } from "./reportWebVitals";
export { default as scrollTop } from "./scrollTop";
export { default as textOrUnknown } from "./textOrUnknown";
