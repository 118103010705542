/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { styled } from "@mui/material/styles";

import { Box, BoxProps, IconButton } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

const CusSubheader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px",
  margin: "0 auto",
});

const CusHeadeingWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Title = styled(Box)({
  fontSize: "14px",
  fontWeight: 600,
});

const Heading = styled(Title)({
  color: "#000",
});

const Subheading = styled(Title)({
  color: "#777",
});

const VerticalDivider = styled(Box)({
  height: "16px",
  width: "1.5px",
  background: "#000",
  margin: "0 12px",
});

const ProgressBar = styled(Box)({
  width: "100%",
  height: "7px",
  background: "#E5ECF6",
  position: "relative",
});

const ActiveProgress = styled(Box, {
  shouldForwardProp: (props) => props !== "progress",
})<{
  progress: number | undefined;
}>(({ theme, progress }) => ({
  width: progress ? `calc(100% * ${progress})` : 0,
  height: "7px",
  background: theme.palette.primary.main,
  position: "absolute",
  top: 0,
  left: 0,
  transition: "width",
}));

export interface ProgressSubheaderProps {
  heading: React.ReactElement | React.ReactNode | string;
  subheading?: string;
  progress?: number;
  progressDisabled?: boolean;
  goBackDisabled?: boolean;
  maxWidth?: BoxProps["maxWidth"];
  onBack: () => void;
  onClose: () => void;
}

export default function ProgressSubheader({
  heading,
  subheading = "",
  progress,
  progressDisabled = false,
  goBackDisabled = false,
  maxWidth = "xl",
  onBack,
  onClose,
}: ProgressSubheaderProps) {
  return (
    <>
      <CusSubheader maxWidth={maxWidth}>
        <IconButton onClick={onBack} disabled={goBackDisabled}>
          <ArrowBackIcon />
        </IconButton>

        <CusHeadeingWrapper>
          <Heading>{heading}</Heading>
          {subheading && (
            <>
              <VerticalDivider />

              <Subheading>{subheading}</Subheading>
            </>
          )}
        </CusHeadeingWrapper>

        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CusSubheader>

      {!progressDisabled && (
        <ProgressBar>
          <ActiveProgress progress={progress} />
        </ProgressBar>
      )}
    </>
  );
}
