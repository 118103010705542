/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PaperProps } from "@mui/material";

import { ColumnInstance, TableToggleHideAllColumnProps } from "react-table";

import { Popup, TransferList, TransferListProps } from "components/core";

import { ColumnView } from "types/global";

import {
  useCreateCustomerHeader,
  useGetCustomerHeadersById,
  useUpdateCustomerHeaders,
} from "hooks/useHeader";

import { EXPANDER_ACCESSOR_ID, HeaderType, SELECTION_ACCESSOR_ID, TableListType } from ".";

const allSkuList: TransferListProps["defaultLeft"] = [
  "wicNumber",
  "usStatus",
  "euStatus",
  "ukStatus",
  "qcStatus",
  "vendorNum",
  "deptNum",
  "collection",
  "frame",
  "colorCode",
  "fabric",
  "fill",
  "finish",
  "function",
  "bom",
  "sizeCode",
  "skuCreateDate",
  "startSellingDate",
  "buyerDesc",
  "subDeptDesc",
  "vendorItemDesc",
  "vendorStyle",
  "countryOfOrigin",
  "skuStatus",
  "usMinPoCancelDate",
  "euMinPoCancelDate",
  "ukMinPoCancelDate",
  "countryCodeList",
  "concept",
];
const newSkuList: TransferListProps["defaultLeft"] = [
  "wicNumber",
  "exportMarket",
  "skuTestLevels",
  "collection",
  "frame",
  "colorCode",
  "sizeCode",
  "skuCreateDate",
  "startSellingDate",
  "buyerDesc",
  "subDeptDesc",
  "vendorItemDesc",
  "vendorStyle",
  "fabric",
  "fill",
  "finish",
  "function",
  "bom",
  "countryOfOrigin",
  "skuStatus",
  "minPoCancelDate",
  "countryCodeList",
  "dcList",
  "deptNum",
  "vendorNum",
  "concept",
];
const submissionList: TransferListProps["defaultLeft"] = [
  "submissionNumber",
  "wicNumber",
  "exportMarket",
  "skuTestLevels",
  "status",
  "vendorNum",
  "requestedDate",
  "ecd",
  "lab",
];

interface ManageColumnPopupProps extends Omit<PaperProps, "onChange" | "onSubmit"> {
  open: boolean;
  title: string;
  submitText: string;
  allCoumns: ColumnInstance<object>[];
  getToggleHideAllColumnsProps: (
    props?: Partial<TableToggleHideAllColumnProps>,
  ) => TableToggleHideAllColumnProps;
  tableType?: TableListType;
  headerId?: string;
  onClose: () => void;
  onSubmit: (columnIds: string[]) => void;
}

function ManageColumnPopup({
  sx,
  title = "Create Column View",
  submitText = "Submit",
  headerId = "",
  open,
  allCoumns = [],
  getToggleHideAllColumnsProps,
  tableType,
  onClose,
  onSubmit,
}: ManageColumnPopupProps) {
  const navigate = useNavigate();

  const [currentList, setCurrentList] = useState<string[]>([]);

  const [columnView, setColumnView] = useState<string>("My View");

  const { isLoading: createHeaderLoading, refetch: createHeader } = useCreateCustomerHeader();
  const { isLoading: updateHeaderLoading, refetch: updateHeader } = useUpdateCustomerHeaders();

  const {
    data: headerData,
    isLoading: featchLoading,
    refetch: fetchHeaderById,
  } = useGetCustomerHeadersById(headerId);

  const isDataLoading = createHeaderLoading || updateHeaderLoading || featchLoading;

  useEffect(() => {
    if (headerId && headerId !== "") {
      fetchHeaderById({}).then(({ data }) => {
        if (data.code === 200) {
          if (data.data.header) {
            setCurrentList(data.data.header.split(","));
          }
        }
      });
    } else {
      setCurrentList([]);
    }
  }, [headerId]);

  useEffect(() => {
    if (!headerData) return;

    setColumnView(headerData?.name);
  }, [headerData]);

  const defaultTempList = useMemo(() => {
    let tempDefaultList;
    if (tableType === "new_skus") {
      tempDefaultList = newSkuList;
    } else if (tableType === "all_skus") {
      tempDefaultList = allSkuList;
    } else if (tableType === "submissions") {
      tempDefaultList = submissionList;
    }

    if (headerData?.header && headerData?.header !== "") {
      tempDefaultList = tempDefaultList?.filter(
        (val) => !headerData?.header.split(",").includes(val),
      );
    }

    return tempDefaultList;
  }, [tableType, headerData]);

  // filter out helper accessors
  const optOutHelperColumns = allCoumns.filter(
    (col) => ![SELECTION_ACCESSOR_ID, EXPANDER_ACCESSOR_ID].includes(col?.id),
  );

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    if (!currentList?.length) return;

    const type =
      tableType === "all_skus"
        ? HeaderType.AllSku
        : tableType === "new_skus"
        ? HeaderType.NewSku
        : HeaderType.Submission;

    const submitData: Partial<ColumnView> = {
      header: currentList?.toString(),
      isCurrent: true,
      name: columnView,
      type,
      id: parseInt(headerId, 10),
    };

    if (headerId && headerId !== "") {
      await updateHeader(submitData);
    } else {
      const { id, ...createPayload } = submitData;

      await createHeader(createPayload);
    }

    await handleClose();
    await navigate(0);
  };

  const handleCheckedProperties = ({ left, right }: { left: string[]; right: string[] }) => {
    setCurrentList(right);
  };

  const handleColumnViewChange = (value: string) => {
    setColumnView(value);
  };

  return (
    <Popup
      maxWidth="md"
      title={title}
      submitText={submitText}
      loading={isDataLoading}
      submitDisabled={isDataLoading}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      {!featchLoading && (
        <TransferList
          allCheckDisabled
          leftText="Unselected Properties"
          rightText="Selected Properties"
          columnView={columnView}
          defaultLeft={defaultTempList}
          defaultRight={currentList}
          loading={isDataLoading}
          onUpdate={(checked) => handleCheckedProperties(checked)}
          onInputChange={handleColumnViewChange}
        />
      )}
    </Popup>
  );
}

export default memo(ManageColumnPopup);
