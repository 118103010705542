import { createReducer } from "@reduxjs/toolkit";
import { CombinedTaskByWicNumber } from "types/sku-group";
import { SkuTestLevelGroup, Task } from "types/task";
import { setCombinedTaskByWicNumber, setOriginalListData, setSelectedGroupData } from "./actions";

export interface SkuGroupingState {
  combinedTasksByWicNumber: CombinedTaskByWicNumber; // for combining multiple market in grouping list
  originalListData: Task[];
  selectedGroups: SkuTestLevelGroup[];
}

const initialState: SkuGroupingState = {
  combinedTasksByWicNumber: {},
  originalListData: [],
  selectedGroups: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setCombinedTaskByWicNumber, (state, action) => ({
      ...state,
      combinedTasksByWicNumber: action.payload,
    }))
    .addCase(setOriginalListData, (state, action) => ({
      ...state,
      originalListData: [...new Set(action.payload)],
    }))
    .addCase(setSelectedGroupData, (state, action) => ({
      ...state,
      selectedGroups: [...new Set(action.payload)],
    })),
);
