export type Order = "asc" | "desc";

export const INITIAL_PER_PAGE_OPTIONS = [10, 15, 20];

export const INITIAL_ROW_PER_PAGE = 10;

export const INITIAL_PAGINATION_STATE = {
  page: 0,
  rowsPerPage: INITIAL_ROW_PER_PAGE,
  rowsPerPageOptions: INITIAL_PER_PAGE_OPTIONS,
  totalRecords: 0,
};

export const ORDER: { [key: string]: Order } = {
  ASC: "asc",
  DESC: "desc",
};

export const ORDER_TYPE = {
  ASC: "asc",
  DESC: "desc",
};
