import { ADMIN, BUYER, EVERYDAY, LAB, SEASONAL, SUPPLIER } from "constants/global";

export type Order = "asc" | "desc";

export interface CustomFilter {
  id: number;
  name: string;
  isCurrent: boolean;
  filters: {
    name: string;
    value: string[];
  }[];
  order: string;
  orderBy: string;
  search: string;
}

export interface AppError {
  code?: string | number | null;
  message?: string;
}

export enum ActiveStatus {
  Active = "active",
  InProgress = "in_progress",
  Inactive = "inactive",
}

export enum Country {
  UK = "UK",
  US = "US",
  EU = "EU",
  QC = "QC",
}

export type Category = typeof EVERYDAY | typeof SEASONAL;

export type UserRole = typeof BUYER | typeof SUPPLIER | typeof LAB | typeof ADMIN;

export type TaskFlowType = "inspection" | "testing" | "samplePickUp";

export type TaskType =
  | "FRI"
  | "FRID"
  | "SPU"
  | "SPUD"
  | "QT"
  | "PST"
  | "SS"
  | "ST"
  | "STD"
  | "DPI"
  | "PAC";

export enum DateFormat {
  DD_MM_YYYY = "DD/MM/YYYY",
  YYYY_MM_DD = "YYYY-MM-DD",
  DD_MMM_YYYY = "DD/MMM/YYYY",
  DD_MM_YYYY_HH_MM = "DD/MM/YYYY hh:mm",
  DD_MMM_YYYY_HH_MM = "DD/MMM/YYYY hh:mm",
  DD_MMM_YYYY_H_MM_A = "DD/MMM/YYYY h:mm a",
  YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DDTHH:mm:ss.SSSZ",
}
export interface NavPageOption {
  name: string;
  href: string;
  icon?: React.ReactElement;
}

export type Currency = "HKD" | "USD" | "RMB" | "INR";

export enum CurrencyEnum {
  HKD = "HKD",
  USD = "USD",
  RMB = "RMB",
  INR = "INR",
}

export interface File {
  id?: number | null;
  fileName: string;
  location?: string;
  fileType?: string;
  type?: string;
  size?: number;
  preview?: any;
}

export interface FilterOptions {
  currentPage: number;
  filters?: { [key: string]: any }[];
  order?: string;
  orderBy?: string;
  rowsPerPage: number;
  search?: string;
  isPage?: boolean; // table view OR tab view
}

export interface ListData<Values> {
  list: Values[];
  page: {
    currentPage: number;
    rowsPerPage: number;
    totalPages: number;
    totalRecords: number;
  };
}

export interface PageInform {
  currentPage: number;
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
}

export interface PrepaymentData {
  hasPrepayment: boolean;
  prepaymentPrice: number;
}

export interface FetchOption {
  name: string;
  id?: string;
  disabled?: boolean;
}

export interface ColumnView {
  id?: number;
  type?: string;
  header: string;
  name: string;
  isCurrent: boolean;
  isDefault: boolean;
}
