/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import React, { memo, useEffect, useMemo, useState } from "react";

import {
  Column,
  HeaderGroup,
  useExpanded,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { INITIAL_PER_PAGE_OPTIONS } from "constants/table";

import scrollTop from "utils/scrollTop";

import {
  Box,
  BoxProps,
  IconButton,
  Paper,
  Skeleton,
  Table as MaUTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Theme } from "@mui/system";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { BasicButton } from "components/core/Button";
import Text from "components/core/Text";
import { useToggle } from "hooks";
import { useAllSkuState } from "state/sku/hooks";

import { ColumnView, Order } from "types/global";
import { CustomHeader } from "types/task";
import { mapToSelectedRows } from "utils/table";

import { useGetCustomerHeaders } from "hooks/useHeader";

import DefaultColumnFilter from "./DefaultColumnFilter";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import ManageColumnPopup from "./ManageColumnPopup";
import TablePagination from "./TablePagination";

import ManageColumnMenu, { ManageColumnMenuOption } from "./ManageColumn/ManageColumnMenu";

const EmptyInfoBox = styled(Box)({
  width: "100%",
  padding: "16px",
  textAlign: "left",
});

const TextButton = styled(BasicButton)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.primary.light,
  textTransform: "initial",
  padding: "6px 8px",
}));

const useStyles = makeStyles((theme: Theme) => ({
  taskFilter: {
    "& .multiselect-container": {
      height: 400,
    },
    "& .optionContainer": {
      height: 355,
    },
  },
  wrapperTable: {
    width: "100%",
    position: "relative",

    "& .MuiTablePagination-root": {
      "& .MuiTablePagination-toolbar": {
        float: "left",
      },
    },
  },
  denseFontSize: {
    "& :not(svg)": {
      fontSize: "12px",
    },
  },
  tablehead: {
    "& tr": {
      minHeight: 40,

      // "& th:first-child": {
      //   borderLeft: `1px solid ${theme.palette.text.primary}`,
      // },

      "& th": {
        borderRight: `0.5px solid ${theme.palette.text.primary}`,
        borderTop: `0.5px solid ${theme.palette.text.primary}`,
        borderBottom: `0.5px solid ${theme.palette.text.primary}`,
      },

      "& th:last-child": {
        borderRight: "0px",
      },
    },
    "& tr th div": {
      color: theme.palette.text.primary,
    },
  },
  tablebody: {
    "& tr": {
      minHeight: 40,

      // "& td:first-child": {
      //   borderLeft: `1px solid ${theme.palette.text.primary}`,
      // },

      "& td": {
        borderRight: `0.5px solid ${theme.palette.text.primary}`,
        borderBottom: `0.5px solid ${theme.palette.text.primary}`,
      },

      "& td:last-child": {
        borderRight: "0px",
      },
    },
  },
  cellPadding: {
    padding: "10px 16px",
  },
  selected: {
    color: theme.palette.primary.main,
  },
  overylay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 999999,
  },
}));

export enum TableListTypeEnum {
  NewSku = "new_skus",
  AllSku = "all_skus",
  Submission = "submissions",
}

export type TableListType =
  | TableListTypeEnum.NewSku
  | TableListTypeEnum.AllSku
  | TableListTypeEnum.Submission;

export enum HeaderType {
  AllSku = "allSku",
  NewSku = "newSku",
  Submission = "submission",
}

export type TableColumn<T extends object = Record<string, unknown>> = Column<T> & {
  canFilter?: boolean;
  cancelFilter?: boolean;
};

export interface ReactTableProps {
  sx?: BoxProps["sx"];
  className?: string;
  columns: any[];
  data: any[];
  count?: number | null; // for list data to be fetched by page
  currentPage?: number | null; // for list data to be fetched by page
  rowsPerPageOptions?: number[]; // for list data to be fetched by page
  sort?: {
    order: Order | null;
    orderBy: string;
  };
  manageColumnDisabled?: boolean;
  defaultColumns?: string[];
  paginationDisabled?: boolean;
  resetAction?: boolean;
  resetActionText?: string;
  tableType?: TableListType;
  dense?: boolean;
  loading?: boolean;
  expanded?: boolean; // for subRows list (collapse)
  isSkipPage?: boolean;
  forceResetSelectedRows?: boolean;
  enableAutoResetSelectedRows?: boolean;
  renderRowSubComponent?: any;
  hideHeaderRowSelection?: boolean;
  headerRowSelectionDisabled?: boolean;
  headerRowSelectionLabel?: string;
  initialRowSelections?: number[];
  disableRowSelections?: number[]; // get by id
  disableRowRemovals?: number[]; // get by id
  dataChangedVersion?: number;
  removal?: boolean; // for delete data
  rowSelection?: boolean; // for checkbox list
  selectedRows?: Record<string, boolean>; // for checkbox list
  pageCheckedIds?: number[]; // for checking data if checked in pages every page fetched
  isClearSelection?: boolean; // for checkbox list
  isManualSort?: boolean;
  isManualFilter?: boolean;
  headerOptions?: CustomHeader;
  onRowRemoval?: (dataId: number) => void;
  onResetAction?: () => void;
  onSelectedRowsChange?: (selectedIds: any) => void; // for checkbox list
  onPageChange?: (event: any, newPage: number) => void; // for list data to be fetched by page
  onClickRow?: (index: any) => void;
  onRowPerPageChange?: (pageSize: number) => void; // handle for row per page change
  onSort?: (name: string, type?: Order | null) => void; // handle sort by api
  onFilter?: (name: string, selected: string[]) => void; // handle filter by api
}

export const REMOVAL_ACCESSOR_ID = "removal";
export const SELECTION_ACCESSOR_ID = "selection";
export const EXPANDER_ACCESSOR_ID = "expander";
export const ACTION_ACCESSOR_ID = "action";

function ReactTable({
  sx,
  className,
  columns,
  data,
  selectedRows,
  pageCheckedIds = [],
  count = null,
  currentPage = null,
  rowsPerPageOptions = INITIAL_PER_PAGE_OPTIONS,
  sort = {
    order: null,
    orderBy: "",
  },
  dense = true,
  resetAction = false,
  resetActionText = "Reset",
  tableType,
  manageColumnDisabled = false,
  defaultColumns = [],
  paginationDisabled = false,
  loading = false,
  removal = false,
  rowSelection = false,
  forceResetSelectedRows = false,
  enableAutoResetSelectedRows = false,
  hideHeaderRowSelection = false,
  headerRowSelectionDisabled = false,
  headerRowSelectionLabel,
  dataChangedVersion,
  initialRowSelections = [],
  disableRowSelections = [],
  disableRowRemovals = [],
  isClearSelection = false,
  isManualSort = false,
  isManualFilter = false,
  expanded = false,
  isSkipPage = false,
  renderRowSubComponent,
  headerOptions,
  onRowRemoval,
  onResetAction,
  onSelectedRowsChange,
  onClickRow,
  onPageChange,
  onRowPerPageChange,
  onSort,
  onFilter,
  ...rest
}: ReactTableProps) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const classes = useStyles();

  const [allSkuState, setAllSkuState] = useAllSkuState();

  const [openManageColumnPopup, toggleManageColumnPopup] = useToggle();

  const [manageColumnType, setManageColumnType] = useState<"create" | "edit" | null>(null);
  const [manageColumnId, setManageColumnId] = useState<string>("");

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

  const [isReset, setIsReset] = useState<boolean>(false);

  const isManualPagination = count !== null && currentPage !== null;

  const columnKeys = columns?.map((col) => col?.accessor);

  const defaultHiddenColumnKeys = defaultColumns?.length
    ? columnKeys?.filter((col) => !defaultColumns?.includes(col))
    : [];

  const tableColumns = useMemo(
    () =>
      loading
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton />,
          }))
        : columns,
    [loading, columns],
  );

  const tableData = useMemo(
    () => (loading ? Array(count || 10).fill({}) : data),
    [loading, JSON.stringify(data)],
  );

  function CustomExpandButton({
    direction = "down",
    disabled = false,
  }: {
    direction: "down" | "up";
    disabled?: boolean;
  }) {
    return (
      <IconButton disabled={disabled}>
        {direction === "down" ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </IconButton>
    );
  }

  const defaultExpanded = data?.reduce((acc, cur, idx) => {
    const strIndex = `${idx}`;
    acc[strIndex] = true;

    return acc;
  }, {});

  const removalColumn = {
    id: REMOVAL_ACCESSOR_ID,
    // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
    Header: () => <Text>Delete</Text>,
    // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
    Cell: (props: any) => {
      const { row, removalActionDisabled } = props;

      return loading ? (
        <Skeleton />
      ) : (
        <IconButton
          disabled={removalActionDisabled}
          onClick={() => {
            if (onRowRemoval) {
              if (onSelectedRowsChange) onSelectedRowsChange(row?.original?.id as number);
              onRowRemoval(row?.original?.id as number);
            }
          }}
        >
          <DeleteOutlineIcon
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              opacity: removalActionDisabled ? "0.3" : 1,
            })}
          />
        </IconButton>
      );
    },
  };

  const rowSelectColumn = {
    id: SELECTION_ACCESSOR_ID,
    // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
    Header: (props: any) => {
      const {
        isHideHeaderRowSelection,
        headerSelectionLabel,
        disableHeaderRowSelection,
        getToggleAllPageRowsSelectedProps,
      } = props;

      return isHideHeaderRowSelection ? (
        <Text>{headerSelectionLabel}</Text>
      ) : (
        <IndeterminateCheckbox
          disabled={disableHeaderRowSelection || loading}
          {...getToggleAllPageRowsSelectedProps()}
        />
      );
    },
    // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
    Cell: (props: any) => {
      const { row, selectionActionDisabled } = props;

      return loading ? (
        <Skeleton />
      ) : (
        <IndeterminateCheckbox
          disabled={selectionActionDisabled}
          {...row.getToggleRowSelectedProps()}
        />
      );
    },
  };

  const rowExpanderColumn = {
    id: EXPANDER_ACCESSOR_ID,
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <CustomExpandButton direction="down" disabled={loading} />
        ) : (
          <CustomExpandButton direction="up" disabled={loading} />
        )}
      </span>
    ),
    Cell: ({ row }: any) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand && expanded ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row
              paddingLeft: `${row.depth * 2}rem`,
            },
          })}
        >
          {row.isExpanded ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {loading ? <Skeleton /> : <CustomExpandButton direction="down" disabled={loading} />}
            </>
          ) : loading ? (
            <Skeleton />
          ) : (
            <CustomExpandButton direction="up" disabled={loading} />
          )}
        </span>
      ) : null,
  };

  const returnedColumns = (tableHooksColumns: any) =>
    rowSelection
      ? [rowSelectColumn, ...tableHooksColumns]
      : expanded
      ? [rowExpanderColumn, ...tableHooksColumns]
      : tableHooksColumns;

  const manualPaginationSetting = {
    autoResetPage: false,
    pageCount: count,
    // manualPagination: true,
  };

  const defaultTableOptions = {
    columns: tableColumns,
    data: tableData,
    initialState: {
      expanded: defaultExpanded,
      pageIndex: isSkipPage ? currentPageIndex : 0,
      hiddenColumns: defaultHiddenColumnKeys,
      selectedRowIds: mapToSelectedRows(initialRowSelections || disableRowSelections),
    },
    // autoResetSelectedRows: false,
    autoResetSelectedRows:
      (enableAutoResetSelectedRows && (dataChangedVersion as number) > 0) || false,
    autoResetRowState: false,
    autoResetPage: false,
    manualSortBy: isManualSort || false,
    manualFilters: isManualFilter || false,
    getRowId: (row: any, relativeIndex: number) => row.id || relativeIndex,
  };

  const tableOptions = isManualPagination
    ? {
        ...defaultTableOptions,
        ...manualPaginationSetting,
      }
    : defaultTableOptions;

  // Use the state and functions returned from useTable to build your UI
  const tableInstance = useTable(
    tableOptions,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      hooks.visibleColumns.push((columns: any) => {
        return removal ? [removalColumn, ...returnedColumns(columns)] : returnedColumns(columns);
      });
    },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows, // row data
    page, // render columns with rowsPerPage
    gotoPage,
    setPageSize,
    visibleColumns,
    allColumns,
    setHiddenColumns,
    setAllFilters,
    getToggleHideAllColumnsProps,
    toggleRowSelected,
    state: { pageIndex, pageSize, selectedRowIds },
  } = tableInstance;

  const handleResetDefaultColumnView = () => {
    setHiddenColumns(defaultHiddenColumnKeys);
  };

  const handleChangePage = (event: any, newPage: number) => {
    if (onPageChange) onPageChange(event, newPage);
    gotoPage(newPage);
    setCurrentPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onRowPerPageChange) {
      onRowPerPageChange(parseInt(event.target.value, 10));
    }
    setPageSize(parseInt(event.target.value, 10));
  };

  // prevent triggering onClick function when selecting text
  const handlePreventTextSelection = (selectedCell: any): void => {
    const hasSelection = !!window.getSelection()?.toString();

    if (!hasSelection) {
      if (onClickRow) onClickRow(selectedCell?.row?.id);
    }
  };

  const handleSort = (name: string, type?: Order | null) => {
    if (onSort) onSort(name, type as Order);
  };

  const handleResetAction = () => {
    setIsReset(true);

    handleResetDefaultColumnView();
    setAllFilters([]);

    if (onResetAction) onResetAction();
  };

  const handleSubmitManageColumn = (columnIds: string[]) => {
    setHiddenColumns(columnIds);
  };

  const handleManageColumnClose = () => {
    toggleManageColumnPopup();
    setManageColumnId("");
    setManageColumnType(null);
  };

  const handleManageColumnCreate = () => {
    setManageColumnType("create");
    toggleManageColumnPopup();
  };

  const handleManageColumnItemEdit = (id: number) => {
    if (id) {
      setManageColumnType("edit");
      setManageColumnId(id.toString());
      toggleManageColumnPopup();
    }
  };

  const handleColumnFilter = (accessor: string) => (selected: string[]) => {
    // todo: pass filters to list for refetch api
    if (onFilter) onFilter(accessor, selected);
  };

  const {
    data: headerData,
    isLoading: headerLoading,
    refetch: fetechHeaders,
  } = useGetCustomerHeaders(tableType as TableListType);

  const handleCheckRow = (selected: Record<string, boolean>) => {
    Object.entries(selected)?.forEach((selectedRow) => {
      const [rowId, checked] = selectedRow;

      if (checked) toggleRowSelected(rowId, checked);
    });
  };

  useEffect(() => {
    // set inital page size by 1st option from rowsPerPageOptions
    setPageSize(rowsPerPageOptions[0]);
  }, []);

  useEffect(() => {
    // todo: fetch column header options
    if (!tableType) return;

    if (tableType !== "submissions") fetechHeaders({});
  }, [tableType]);

  useEffect(() => {
    if (isReset) setIsReset(false);
  }, [isReset]);

  useEffect(() => {
    scrollTop();
  }, [pageIndex, pageSize]);

  // go to latest page after fetching page data from api
  useEffect(() => {
    if (!isManualPagination) return;
    gotoPage(currentPage);
  }, [pageIndex, isManualPagination, currentPage, loading]);

  useEffect(() => {
    // Bubble up the selection to the parent component
    if (onSelectedRowsChange) onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  useEffect(() => {
    if (isClearSelection) tableInstance.toggleAllRowsSelected();
  }, [isClearSelection, tableInstance]);

  useEffect(() => {
    // set initial selected rows
    if (!selectedRows) return;

    handleCheckRow(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    setAllFilters([]);
    // toggleAllRowsSelected(false);

    if (pageCheckedIds?.length) {
      handleCheckRow(mapToSelectedRows(pageCheckedIds));
    }
  }, [data]);

  useEffect(() => {
    if (!forceResetSelectedRows || !Object.entries(selectedRowIds).length) return;

    // reset to unchecked for selected row ids
    Object.entries(selectedRowIds)?.forEach((item: any[]) => {
      const [rowId] = item;

      toggleRowSelected(rowId, false);
    });
  }, [forceResetSelectedRows, selectedRowIds]);

  const manageColumnMenuList: ManageColumnMenuOption[] = useMemo(() => {
    const mappedMenu: ManageColumnMenuOption[] = [];

    if (headerData?.length) {
      headerData?.forEach((item: ColumnView) => {
        if (item?.isCurrent) {
          setAllSkuState({ currentHeader: item?.header });
        }

        mappedMenu.push({
          isDefault: item?.isDefault,
          isCurrent: item?.isCurrent,
          label: item?.name,
          id: item?.id as number,
          onEdit: () => handleManageColumnItemEdit(item.id as number),
        });
      });
    }

    return mappedMenu;
  }, [headerData]);

  const isEditColumnViewType = manageColumnType === "edit";

  return (
    <Box sx={sx}>
      <ManageColumnPopup
        title={isEditColumnViewType ? "Edit Column View" : "Create Column View"}
        submitText={isEditColumnViewType ? "Edit" : "Submit"}
        tableType={tableType}
        headerId={manageColumnId}
        open={openManageColumnPopup}
        allCoumns={allColumns}
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        onClose={handleManageColumnClose}
        onSubmit={handleSubmitManageColumn}
      />
      <Paper data-cy="table-paper" className={classnames(className, classes.wrapperTable)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!manageColumnDisabled && (
            <ManageColumnMenu
              label="Manage Column"
              menuList={manageColumnMenuList}
              onCreate={handleManageColumnCreate}
              onClickDefaultView={handleResetDefaultColumnView}
            />
          )}

          {resetAction && (
            <TextButton variant="text" onClick={handleResetAction}>
              {resetActionText}
            </TextButton>
          )}

          {!paginationDisabled && (
            <TablePagination
              count={count || rows.length} // count rows length
              page={currentPage || pageIndex}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>

        <TableContainer className={classnames({ [classes.denseFontSize]: dense })}>
          <MaUTable
            data-cy-length={tableData.length}
            size={dense ? "small" : undefined}
            {...rest}
            {...getTableProps()}
          >
            <TableHead className={classes.tablehead}>
              {headerGroups.map((headerGroup: HeaderGroup<object>) => (
                // eslint-disable-next-line react/jsx-key
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: number) => {
                    const isHelperColumn = [
                      REMOVAL_ACCESSOR_ID,
                      SELECTION_ACCESSOR_ID,
                      EXPANDER_ACCESSOR_ID,
                    ].includes(column.id);

                    return (
                      <TableCell
                        key={index}
                        sx={{
                          minWidth: column.minWidth,
                        }}
                      >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box>
                            {column.render("Header", {
                              isHideHeaderRowSelection: hideHeaderRowSelection,
                              disableHeaderRowSelection: headerRowSelectionDisabled,
                              headerSelectionLabel: headerRowSelectionLabel,
                            })}
                          </Box>

                          {/* sorting label */}
                          <Box display="flex">
                            {!column.disableSortBy && !isHelperColumn ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                              >
                                {(sort.order && sort.orderBy === column.id) || column.isSorted ? (
                                  sort.order === "desc" || column.isSortedDesc ? (
                                    <KeyboardArrowDownIcon
                                      color="inherit"
                                      className={classnames({
                                        [classes.selected]:
                                          sort.orderBy === column.id || column.isSorted,
                                      })}
                                      onClick={() => handleSort(column.id, "asc")}
                                    />
                                  ) : (
                                    <KeyboardArrowUpIcon
                                      className={classnames({
                                        [classes.selected]:
                                          sort.orderBy === column.id || column.isSorted,
                                      })}
                                      onClick={() => handleSort("", null)}
                                    />
                                  )
                                ) : (
                                  <UnfoldMoreIcon onClick={() => handleSort(column.id, "desc")} />
                                )}
                              </Box>
                            ) : null}

                            <Box>
                              {!column?.disableFilters && !isHelperColumn ? (
                                <DefaultColumnFilter
                                  isReset={isReset}
                                  customFilterDisabled={onFilter === undefined}
                                  header={headerOptions}
                                  column={column}
                                  tableType={tableType}
                                  onFilter={handleColumnFilter(column?.id as string)}
                                />
                              ) : null}
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()} className={classes.tablebody}>
              {!data && !loading && !page?.length ? (
                <TableRow>
                  <TableCell colSpan={visibleColumns.length}>
                    {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                    <EmptyInfoBox>No data found</EmptyInfoBox>
                  </TableCell>
                </TableRow>
              ) : (
                page?.map((row: any, index: any) => {
                  prepareRow(row);
                  const isSubRow = row.depth > 0;

                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        selected={row.isSelected}
                        aria-checked={row.isSelected}
                        sx={{
                          backgroundColor: isSubRow ? "rgba(0, 0, 0, 0.02)" : "",
                        }}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell: any) => {
                          const checkHelperColumn = (accessorId: string) =>
                            accessorId === cell.column.id;

                          const checkDisableAction = (
                            isSelectedAccessor: boolean,
                            disableIds: number[],
                          ) =>
                            isSelectedAccessor &&
                            disableIds.includes(parseInt(row.original.id, 10));

                          // avoid propagation for expander button
                          const isExpander = checkHelperColumn(EXPANDER_ACCESSOR_ID);
                          const isAction = checkHelperColumn(ACTION_ACCESSOR_ID);

                          // for fixing expanded table with clicking row function
                          const parentRowIndex = Math.floor(parseInt(row.id, 10));

                          // for disable cell when is target accessor & in disable list
                          const isRemoval = checkHelperColumn(REMOVAL_ACCESSOR_ID);

                          const isSelection = checkHelperColumn(SELECTION_ACCESSOR_ID);

                          const removalActionDisabled = checkDisableAction(
                            isRemoval,
                            disableRowRemovals,
                          );

                          const selectionActionDisabled = checkDisableAction(
                            isSelection,
                            disableRowSelections,
                          );

                          return (
                            <TableCell
                              key={cell?.column.id}
                              sx={{
                                cursor:
                                  onClickRow && typeof onClickRow === "function"
                                    ? "pointer"
                                    : "auto",
                              }}
                              className={classes.cellPadding}
                              {...cell.getCellProps()}
                              data-cy={cell.column.id}
                              onClick={() =>
                                !isExpander && !isAction ? handlePreventTextSelection(cell) : null
                              }
                            >
                              {/* pass removalActionDisabled & selectionActionDisabled for action columns */}
                              {cell.render("Cell", {
                                removalActionDisabled,
                                selectionActionDisabled,
                              })}
                            </TableCell>
                          );
                        })}
                      </TableRow>

                      {renderRowSubComponent && row.isExpanded ? (
                        <TableRow>
                          <TableCell colSpan={visibleColumns.length}>
                            {renderRowSubComponent({ row })}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </React.Fragment>
                  );
                })
              )}
            </TableBody>
          </MaUTable>
        </TableContainer>

        {!paginationDisabled && (
          <TablePagination
            count={count || rows.length} // count rows length
            page={currentPage || pageIndex}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {loading && <Box className={classes.overylay} />}
      </Paper>
    </Box>
  );
}

export default memo(ReactTable);
