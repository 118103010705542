import { configureStore } from "@reduxjs/toolkit";

import application from "./application/reducer";
import sku from "./sku/reducer";
import skuGrouping from "./skuGrouping/reducer";

const store = configureStore({
  reducer: {
    //* insert reducer here *//
    application,
    sku,
    skuGrouping,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
  devTools: process.env.NODE_ENV === "development",
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
