import { GridProps } from "@mui/material";
import { AutocompleteOption, CheckboxesTagsOption } from "components/core";

export interface SubHeaderButtonList {
  id: string;
  component: React.ReactElement;
}

export interface ActionMenu {
  label: any;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

export type TabPanelType = { name?: string; id: any; component: any; disabled?: boolean };

export interface ListOption {
  currentPage?: number;
  filters?: any[];
  order?: string;
  orderBy?: string;
  rowsPerPage?: number;
  search?: string;
}

export interface MappingGridData extends GridProps {
  label: string;
  name: string;
  value: string | null | undefined;
  inputReadOnly?: boolean;
  inputType?:
    | "textField"
    | "datePicker"
    | "autocomplete"
    | "autocompleteCreatable"
    | "checkboxesTags";
  inputOptions?: AutocompleteOption[] | CheckboxesTagsOption[];
  division?: boolean;
  disabled?: boolean;
}

export enum ChangeLogType {
  Add = "ADD",
  Update = "MOD",
}

export interface HitoryChangeField {
  before: string;
  after: string;
  remark: string;
  name: string;
}

export interface History<Data> {
  before: Data;
  after: Data;
  type: ChangeLogType;
  changedFields: HitoryChangeField[];
  changes: string[];
  timestamp: number;
  user: string;
}

export interface ResetPasswordValues {
  password: string;
  token: string;
}

export interface UserInfo {
  email: string;
  buyerInfo?: {
    deptNum: string;
    name: string;
    buyerRole: string;
  };
}
