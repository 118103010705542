import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import { useEffect, useMemo, useState } from "react";

import { useErrorAlert, useSuccessAlert, useUserRole, useUserRoleUrl } from "hooks";

import API from "constants/api";

import { BUYER, SUPPLIER } from "constants/global";
import { FilterOptions } from "types/global";
import { CustomHeader } from "types/task";
import { TestItem, TestItemHistory } from "types/test-item";

export function useFetchTestItem<T>({
  method = "GET",
  api = API.TEST_ITEM,
  id = "",
  url = "",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
}) {
  const { setError } = useErrorAlert();

  const PATH = useUserRoleUrl(api);

  const [data, setData] = useState<T>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: data as T,
      isLoading,
      refetch,
      error,
    }),
    [id, url, data, isLoading],
  );
}

function useUpdateTestItem<Req, Res = { id?: string }>({
  method = "POST",
  api = API.TEST_ITEM,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const { isBuyer } = useUserRole();

  const PATH = isBuyer ? `/${BUYER}${api}` : api;

  const [data, setData] = useState<Res>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (payload: Req) => refetch({ data: payload }),
      error,
    }),
    [api, id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

export function useTestItemList() {
  const { setError } = useErrorAlert();

  const { isBuyer, isSupplier } = useUserRole();

  const PATH = isBuyer
    ? `/${BUYER}${API.TEST_ITEM}`
    : isSupplier
    ? `/${SUPPLIER}${API.TEST_ITEM}`
    : API.TEST_ITEM;

  const [{ loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${PATH}/list`,
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      isLoading,
      refetch: (payload: FilterOptions) => refetch({ data: payload }),
      error,
    }),
    [isLoading],
  );
}

export function useTestItem<T = TestItem>(testItemId: string) {
  return useFetchTestItem<T>({ id: testItemId });
}

export function useTestItemHistory<T = TestItemHistory[]>(testItemId: string) {
  return useFetchTestItem<T>({ url: `${testItemId}/history` });
}

export function useArchiveTestItem<T = { comment: string }>(testItemId: number) {
  return useUpdateTestItem<T>({
    method: "POST",
    id: testItemId?.toString(),
    url: "/archive",
    successAlertEnabled: true,
    successMessage: "Test item archived",
  });
}

export function useTestItemHeaderOptions<R = CustomHeader>() {
  return useUpdateTestItem<any, R>({
    method: "POST",
    url: "filter_options",
  });
}
