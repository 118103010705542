import React, { useState, useEffect, useMemo } from "react";
import useAxios from "axios-hooks";

import { useErrorAlert, useSuccessAlert } from "hooks";

import { File } from "types/global";

import API from "constants/api";
import { ItemSupplierFactory } from "../types/item";

export function useUploadFile() {
  const { setError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const [file, setFile] = useState<File>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: API.UPLOAD_FILE,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    {
      manual: true,
      autoCancel: false, // avoid cancel request when uploading multiple files
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data?.code === 200) setSuccess("File has been uploaded successfully.");

    if (fetchData?.data) setFile(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: file,
      isLoading,
      refetch: (payload: FormData) => refetch({ data: payload }),
      error,
    }),
    [fetchData, isLoading],
  );
}

export function useUploadTestItemFiles() {
  const { setError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const [file, setFile] = useState<File>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: API.UPLOAD_TEST_ITEM_FILE,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    {
      manual: true,
      autoCancel: false, // avoid cancel request when uploading multiple files
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data?.code === 200) setSuccess("File has been uploaded successfully.");

    if (fetchData?.data) setFile(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: file,
      isLoading,
      refetch: (payload: FormData) => refetch({ data: payload }),
      error,
    }),
    [fetchData, isLoading],
  );
}

export function useDownloadFile() {
  const { setError } = useErrorAlert();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      data: fetchData?.data,
      isLoading,
      refetch: (location: File["location"]) => refetch({ url: `${API.DOWNLOAD_FILE}/${location}` }),
      error,
    }),
    [fetchData, isLoading],
  );
}

export function useDownloadTRF() {
  const { setError } = useErrorAlert();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      data: fetchData?.data,
      isLoading,
      refetch: (relatedId: string) => refetch({ url: `/trf/${relatedId}` }),
      error,
    }),
    [fetchData, isLoading],
  );
}

export function useUploadMondayFeed() {
  const { setError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const [file, setFile] = useState<File>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: "buyer/daily_po/rh_gm_master_list/upload",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    },
    {
      manual: true,
      autoCancel: false, // avoid cancel request when uploading multiple files
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data?.code === 200) setSuccess("File has been uploaded successfully.");

    if (fetchData?.data) setFile(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: file,
      isLoading,
      refetch: (payload: FormData) => refetch({ data: payload }),
      error,
    }),
    [fetchData, isLoading],
  );
}
