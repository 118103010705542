import { CurrencyEnum } from "types/global";

export const STORAGE_TOKEN_KEY = "token";
export const STORAGE_EMAIL_KEY = "email";
export const STORAGE_ROLE_KEY = "role";
export const STORAGE_ALL_SKU_CUSTOM_FILTERS_KEY = "allSkuCustomFilters";
export const STORAGE_NEW_SKU_CUSTOM_FILTERS_KEY = "newSkuCustomFilters";

export const BUYER_ROLE_KEY = "buyerRole";

export const DRAWER_HEADER_WIDTH = 240;

export const NOT_APPLICABLE = "N/A";

export const YES_NO_OPTIONS = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "0",
  },
];

export const ACTIVE_STATUS = {
  active: "Active",
  in_progress: "In Progress",
  inactive: "Inactive",
};

export const CURRENCY_OPTIONS = [
  { label: CurrencyEnum.HKD, value: CurrencyEnum.HKD },
  { label: CurrencyEnum.RMB, value: CurrencyEnum.RMB },
  { label: CurrencyEnum.USD, value: CurrencyEnum.USD },
  { label: CurrencyEnum.INR, value: CurrencyEnum.INR },
];

export const MARKET_OPTIONS = [
  { label: "All", value: "all" },
  { label: "Asia", value: "1" },
  { label: "US", value: "2" },
  { label: "UK", value: "3" },
];

export const LATEST_OPTIONS = [
  {
    label: "Latest",
    value: "desc",
  },
  {
    label: "Oldest",
    value: "asc",
  },
];

export const ORDER: { [key: string]: string } = {
  desc: "Descending",
  asc: "Ascending",
};

export const EVERYDAY = "everyday";
export const SEASONAL = "seasonal";

export const ADMIN = "admin";
export const BUYER = "buyer";
export const SUPPLIER = "supplier";
export const LAB = "lab";
export const LAB_ADMIN = "lab_admin";
export const SUPPLIER_AGNECY = "supplier_ag";

export const USER_ROLE: { [key: string]: string } = {
  admin: "Admin",
  buyer: "Buyer",
  lab: "Lab",
  lab_admin: "Lab Admin",
  supplier: "Supplier",
  supplier_ag: "Supplier Agency",
};
